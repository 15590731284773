
@media (min-width: 480px){
	
}

@media (min-width: 576px) {
	body{
        &.detail {
            .wrapper section.section-detail .description .description-left .content-contact {
                position: relative;
                h5 {
                    display: block;
                }
                .contact-phone a {
                    padding: 8px 40px;
                    margin: 2px;
                    border-radius: 5px;
                    flex: unset;
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .navbar-expand-md .navbar-collapse {
        justify-content: flex-end;
    }
    body {
        &.detail {
            .wrapper .content-important {
                margin-top: 0;
            }
        }
    }
}
@media screen and (min-width: 992px) {
    body {
        &.home {
            section {
                &.section-comunidad {
                    padding: 80px 0px 80px 0px;
                    background-size: 50vw auto;
                    .asesoria-overlay {
                        background-image: linear-gradient(270deg, $color_cyan 50%, rgba(56, 125, 255, 0.48) 100%);
                    }
                    .content-asesoria {
                        margin: 0% 0% 0% 50%;
                        p {
                            font-size: 32px;
                            line-height: 36px;
                        }
                    }
                }
                &.section-contact {
                    .contact-header {
                        flex-direction: row;
                        span {
                            width: 64px;
                            height: 64px;
                            svg, img {
                                width: 32px;
                                height: 32px;
                            }
                        }
                        .box-wrapper {
                            margin-bottom: 0;
                            .box-content {
                                h4 {
                                    margin-bottom: 0.5rem;;
                                }
                            }
                        }                    
                    }
                    .contact-body .content-form {
                        padding: 40px 40px 24px 40px;
                    }
                }
                
            }
            &.contact {
                section.section-comunidad {
                    height: 400px;
                }
            }
        }
        &.detail  {
            .wrapper section.section-detail {
                h2 {
                    font-size: 90px;
                }
                .slider-mascota {
                    .slider-big img {
                        height: 600px;
                    }
                    .slider-small img {
                        width: 120px;
                        height: 80px;
                    }
                }
                
                .description .description-rigth {
                    padding-left: 27px;
                }
                .description .description-rigth .mascota-credits {
                    margin-top: 0;
                }
                
            }
                       
            
            
        }
    }
}
@media (min-width: 1400px) {
    body {
        &.tags {
            .section-map {
                height: 500px;                
            }
        }
        &.home {
            &.contact {
                section.section-comunidad {
                    height: 500px;
                }
            }
        }
    }
}
//Bootstrap
@media (max-width: 767px){
    body {
        &.page {
            main {
                margin-top: 53px;
            }
        }
        header {            
            nav.navbar {
                padding: 0;
                a.logo {
                    img {
                        width: 100px;
                    }
                }
                ul.navbar-nav {
                    li {
                        &.nav-item.search {
                            display: none;
                        }
                        &.nav-item.social-media {
                            display: none;
                        }                        
                    }
                }
                .rrss-m {
                    display: flex;
                }
                .btn-search {
                    display: inline-block;
                }
            }
        }
    }
}
@media (max-width: 576px){
    
    body {
        &.home {
            .slider-map-home {
                max-height: 150px;
                .mascota-item a {
                    height: 150px;
                    img {
                        &.img-perfil {
                            height: 150px;
                        }                        
                        &.img-country {
                            width: 30px;
                        }
                    }
                    .caracteristicas .name {
                        font-size: 12px;
                    }
                }
                
            }
            
        }
        
    }
    .modal {
        &.modal-share {
            top: auto;
            bottom: 0px;
            height: auto;
            overflow: unset;         
            .modal-dialog {
                margin: 0;
                .modal-content, .modal-header {
                    border-radius: unset;
                }
                .modal-content {
                    border: unset;
                }
                .modal-header{
                    padding-top: 12px;
                    padding-bottom: 12px;
                    .fs-5 {
                        font-size: 18px !important;
                    }
                    button {                        
                        box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08);
                        top: -24px;
                    }
                }
                .modal-body {
                    padding-top: 2px;
                    padding-bottom: 0;
                    form {
                        input {
                            height: 40px;
                            font-size: 14px;
                        }
                        button {
                            font-size: 14px;
                            width: 80px;
                            min-width: auto;
                        }
                    }
                }
                .modal-footer {
                    ul.share-rrss {
                        li {
                            a {
                                span {
                                    width: 44px;
                                    height: 44px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .huellas-toast {
        .huellas-toast-notice {
            left: auto;
        }
    }
    hide{
        display: none
    }
    
    .video_tiktok {
        blockquote {
            max-width: 100% !important;
        }
    }

    .video_youtube {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    
}

