body {
	&.login {
        background-color: $color_white;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 638px;
            height: 345px;
            background-image: url(../images/bg-huellas-top.png);
            background-repeat: no-repeat;
        }
        &:after {
            content: "";
            position: fixed;
            bottom: 0;
            left: 0;
            width: 843px;
            height: 449px;
            background-image: url(../images/bg-huellas-bottom.png);
            background-repeat: no-repeat;
        }
		section {
            position: relative;
            &.section-login {
                position: relative;
                z-index: 1;
                margin-top: 150px;
                h1 {
                    margin: 50px 16px;
                    font-size: 46px;
                    line-height: 1.21;
                    letter-spacing: normal;
                    color: rgb(46, 44, 54);
                    font-weight: bold;
                }
                .content-login {
                    background: #eeeeee;
                    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
                    padding: 3rem !important;
                    max-width: 480px;
                    margin: 0 auto;
                    border-radius: 15px;
                }
                .content-rrss {                    
                    .btn-login {
                        width: 100%;
                        max-width: 325px;
                        height: 48px;
                        border-radius: 8px;
                        display: flex;
                        -webkit-box-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        justify-content: center;
                        background-color: $color_pink;
                        color: $color_white;
                        appearance: none !important;
                        transition: .2s;
                        svg {
                            width: 25px;
                        }
                        span {
                            font-size: 16px;
                            font-weight: bold;
                            padding-left: 10px;
                        }
                        &:hover {
                            background-color: $color_cyan;
                        }
                    }
                }
                .content-video {
                    overflow: hidden;
                    border-radius: 15px;
                }
            }
        }
    }
}
