.modal {
    &.modal-share {
        .modal-header, .modal-footer {
            border: 0;
        }
        .modal-header {
            button {
                transition: transform 100ms cubic-bezier(0,0,.38,.9) 400ms;
                position: absolute;
                border-radius: 50%;
                width: 48px;
                height: 48px;
                align-items: center;
                border: none;
                cursor: pointer;
                display: inline-flex;
                justify-content: center;
                background: $color_white;
                right: 15px;
                text-align: center;
                span {
                    width: 24px;
                    height: 24px;
                    margin: auto;
                }
            }
        }
        form {
            display: flex;
            input {
                border-right: 0;
                flex: 1;
                height: 48px;
                border: 1px solid #2d2f31;
                border-radius: 0;
                -webkit-appearance: none;
                display: block;
                padding: 0 16px;
                width: 100%;
                font-weight: 400;
                //line-height: 16px;
                font-size: 16px;
            }
            button {
                background-color: #2d2f31;
                color: $color_white;
                position: relative;
                align-items: center;
                border: none;
                cursor: pointer;
                display: inline-flex;
                min-width: 100px;
                padding: 0 1.2rem;
                justify-content: center;
                user-select: none;
                -webkit-user-select: none;
                vertical-align: bottom;
                white-space: nowrap;
                font-weight: 700;
                line-height: 1.2;
                letter-spacing: 0;
                font-size: 16px;
                border-radius: unset;
                &:hover {
                    background-color: #3e4143;
                }
            }
        }
        ul.share-rrss {
            padding-left: 0;
            li {
                display: inline-block;
                width: 64px;
                text-align: center;
                a {
                    display: inline-block;               
                    span {
                        border: 1px solid #2d2f31;
                        width: 48px;
                        height: 48px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        svg {
                            width: 18px;
                            height: 18px;
                        }                          
                    }
                    div {
                        font-size: 12px;
                        margin-top: 5px;
                        color: $color_black;
                    }                     
                    &:hover {
                        span {
                            background-color: rgba(3, 142, 174, .12);
                        }
                        div {
                            color: $color_cyan;
                        }                        
                    }
                }
            }
        }
    }
}