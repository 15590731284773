* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

*:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    position: relative;
}
body {
    font-family: 'Poppins', sans-serif !important;
    background-color: #eaeded;
    height: 100%;
    h1, h2, h3, h4, h5, h6 {
        font-weight: 700;
    }
    h2 {
        font-size: 24px;
        color: $color_gray;
        font-weight: 700;
        position: relative;
        margin-bottom: 10px;
    }    
    a {
        color: $color_cyan;
        text-decoration: none;
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease;
    }
    a, select, input, button {
        &:hover, &:focus {
            outline: none;
            box-shadow: none;
        }
    }
    ul {
        margin: 0;
        padding-left: 0;
        li {
            list-style-type: none;
        }
    }
    img {
        max-width: 100%;
    }
    &.page {
        main {
            margin-top: 78px;
        }
    }

}
.bg-white {
    background-color: $color_white;
}
hr {
    width: 100%;
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}


.loading2 {
    position: relative;
    text-align: center;
    height: 265px;
    .loading-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        .sr-only {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            clip: rect(0,0,0,0);
            border: 0;
            white-space: nowrap;
            clip-path: inset(50%);
        }
    }
}
//
.loading {
    width: 100%;
    .spinner {
        height: 300px;
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        z-index: 100;
        //-webkit-animation: head-move 1s ease-in-out infinite;
        -webkit-animation: heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
    }
}

.map-reference {
    width: 100%;
    //height: 650px;
    //height: calc(100% - 56px);
    background-color: $color_black;
}

.wha-fixed {
    position: fixed;
    right: 10px;
    top: 50%;
    z-index: 9999;
    background-color: #3fc350;
    padding: 12px 0px 10px 0px;
    line-height: 18px;
    text-align: center;
    color: $color_white;
    font-size: 17px;
    border-radius: 50%;
    margin-top: 15px;
    //box-shadow: 0px 0px 5px rgba(60,60,60,.4);
    box-shadow: 0 0 8px $color_black;
    cursor: pointer;
    width: 60px;
    height: 60px;
    transform: translate(0, -50%);
    &:hover {
        box-shadow: 0 0 8px #c2c2c2;  
    }
    img {
        width: 35px;
    }
}

//
/*
.heart {
    display: inline-block;
    position: relative;
    width: 300px;
    height: 300px;
    transform: rotate(45deg);
    //transform-origin: 40px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .heart div {
    //top: 50%;
    //left: 50%;
    //position: absolute;
    //transform: translate(-50%, -50%);
    width: 52px;
    height: 52px;
    background: #ff0302;
    animation: heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .heart div:after,
  .heart div:before {
    content: " ";
    position: absolute;
    display: block;
    width: 52px;
    height: 52px;
    background: #ff0302;
  }
  .heart div:before {
    left: -30px;
    border-radius: 50% 0 0 50%;
  }
  .heart div:after {
    top: -30px;
    border-radius: 50% 50% 0 0;
}
*/
.bg-pink {
    background-color: $color_pink;
    &:hover {
        background-color: $color_pink;
        opacity: .8;
    }
}