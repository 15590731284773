header {
    z-index: 3;
    a.logo {
        img {
            width: 122px;
        }
    }    
    nav {
        .btn-search {
            display: none;
            background-color: $color_pink;
            color: $color_white;
            border: 1px solid $color_pink;
            border-radius: 25px;
            padding: 2px 15px;
            font-size: 12px;
            svg {
                width: 18px;
                margin-right: 2px;
                fill: $color_white;
            }
            &:hover, &.active {
                background-color: transparent;
                border: 1px solid $color_pink;
                span {
                    color: $color_pink;
                    svg {
                        fill: $color_pink;
                    }
                    &:before {
                        display: none;
                    }
                }
            }
        }
        .rrss-m {
            display: none;
            flex-direction: column;
            margin-top: 50px;
            h4 {
                color: #ccc;
                font-size: 18px;
                font-weight: 300;
            }
            .content-social-media {
                display: flex;
                justify-content: center;
                align-items: center;    
                margin-top: 10px;                    
                .social-media {
                    &:not(:last-child) {
                        margin-right: 20px;
                    }
                    a {
                        svg {
                            width: 22px;
                            height: 22px;
                            fill: $color_white;
                        }
                    }
                }
            }
            
        }
        ul.navbar-nav {
            li.nav-item {
                a.nav-link {
                    color: $color_black;
                    span {
                        position: relative;
                        display: block;
                        padding-bottom: 2px;
                        &:before {
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 2px;
                            bottom: 0;
                            left: 0;
                            background: $color_pink;
                            visibility: visible;
                            -webkit-transform: scaleX(0);
                            -moz-transform: scaleX(0);
                            -ms-transform: scaleX(0);
                            -o-transform: scaleX(0);
                            transform: scaleX(0);
                            -webkit-transition: all 0.3s ease-in-out 0s;
                            -moz-transition: all 0.3s ease-in-out 0s;
                            -ms-transition: all 0.3s ease-in-out 0s;
                            -o-transition: all 0.3s ease-in-out 0s;
                            transition: all 0.3s ease-in-out 0s;
                        }
                    }
                    &:hover, &.active {
                        span {
                            color: $color_pink;
                            &:before {
                                visibility: visible;
                                -webkit-transform: scaleX(1);
                                -moz-transform: scaleX(1);
                                -ms-transform: scaleX(1);
                                -o-transform: scaleX(1);
                                transform: scaleX(1);
                            }
                        }
                    }
                    &.active {
                        background-color: transparent;
                        span {
                            color: $color_pink;
                        }
                        /*&:hover {
                            span {
                                &:before {
                                    display: none;
                                }
                            }
                        }*/
                    }
                    
                }
                &.search {
                    margin-left: 15px;
                    a.nav-link {
                        background-color: $color_pink;
                        color: $color_white;
                        border: 1px solid $color_pink;
                        border-radius: 25px;
                        padding: 8px 25px;
                        svg {
                            width: 22px;
                            margin-right: 2px;
                            fill: $color_white;
                        }
                        &:hover, &.active {
                            background-color: transparent;
                            border: 1px solid $color_pink;
                            span {
                                color: $color_pink;
                                &:before {
                                    display: none;
                                }
                                svg {
                                    fill: $color_pink;
                                }
                            }
                        }
                    }
                }
                &.social-media {
                    &.first-child {
                        margin-left: 30px;
                    }
                    a {
                        span {
                            svg {
                                width: 32px;
                                height: 32px;
                            }
                        }
                        &:hover, &.active {
                            span {
                                &:before {
                                    display: none;
                                }
                            }
                        }                        
                        &.fb {                            
                            &:hover {
                                fill: $color_cyan;
                            }
                        }
                        &.tt {                            
                            &:hover {
                                fill: $color_pink;
                            }
                        }
                    }
                }
            }
        }
    }
}
/*.header-top {
    position: relative;
    background-color: $color_white;
}*/
body {
    &.open {
        overflow-y: hidden;
        header {
            nav {
                ul.navbar-nav {
                    li.nav-item a.nav-link {
                        color: $color_white;
                    }
                }
                
                .navbar-collapse {
                    background: $color_black !important;
                    height: 100%;
                    height: calc(100% - 53px);
                    width: 100%;
                    position: fixed;
                    top: 53px;
                    left: 0;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
            }
        }
        .wha-fixed {
            display: none;
        }
    }
}