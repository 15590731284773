body {
    &.legal {
        .bg-legal {
            background-color: $color_white;
            padding: 1.25rem;
        }
        h3 {
            font-size: 20px;
            margin-top: 30px;
            margin-bottom: 15px;
        }
    }
}