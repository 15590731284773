body {
    &.detail {
        .wrapper {
            width: 100%;
            margin-right: auto;
            margin-left: auto;
            max-width: 956px;
            background-color: $color_white;
            box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
            section {
                &.section-detail {
                    padding-bottom: 50px;
                    h1 {
                        margin-top: 0;
                        margin-bottom: 15px;
                        padding-top: 0;
                        text-align: center;
                        font-size: 21px;
                        font-weight: 600;                        
                    }
                    h2 {
                        font-size: 38px;
                        font-weight: 900;
                        color: $color_pink;
                        text-transform: uppercase;
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        justify-content: center;
                        margin-top: 20px;
                        padding-top: 20px;
                    }
                    .ubigeo {
                        text-align: center;
                        margin-bottom: 10px;
                        a {
                            display: inline-block;
                            font-size: 13px;
                            white-space: nowrap;
                            padding: 4px 8px 5px 8px;
                            border-radius: 3px;
                            color: #2A5279;
                            background-color: #DEF2FC;
                            margin: 4px;
                            text-overflow: ellipsis;
                        }
                    }
                    .slider-mascota {
                        width: 100%;
                        margin: 0 auto;
                        text-align: center;
                        .slider-big  {
                            img {
                                height: 300px;
                                object-fit: cover;
                                //cursor: pointer;
                                width: auto;
                                max-width: 100%;
                                z-index: 2;
                                position: relative;
                            }                            
                            .slick-list {
                                background-image: url(../images/bg-huellas365-slider.png);
                                background-repeat: no-repeat;
                                background-position: center center;
                                background-attachment: fixed;
                                background-size: cover;
                                background-color: #222222;
                            }
                        }
                        .slider-small {
                            img {
                                width: 80px;
                                height: 60px;
                                overflow: hidden;
                                object-fit: cover;
                                cursor: pointer;
                                opacity: 0.6;
                            }
                            .bg-mascota {
                                display: inline-block;
                                background-color: $color_white;
                                &:hover {
                                    img {
                                        opacity: 1 !important;
                                    }
                                }
                            }
                            .slick-active.slick-current.slick-center  {                                
                                img {
                                    opacity: 1 !important;
                                }
                                
                            }                         
                        }
                        .slider-small-bottom {
                            background-color: #222222;
                            //background: repeating-linear-gradient(-80deg,rgba(255,3,2,.2),rgba(255,3,2,.2) 5px,#fff 0,#fff 10px);
                            //background-attachment: fixed;
                            //background-position: 50%;
                            //background-repeat: no-repeat;
                            //background-size: cover;
                            position: relative;
                            padding: 10px 0;
                            width: 100%;
                        }
                    }
                    .mascota-date {
                        display: flex;
                        background: #f2f2f2;
                        min-height: 52px;
                        padding: 0 15px;
                        .published {
                            display: flex;
                            width: 50%;
                            align-items: center;
                            justify-content: flex-start;
                            i {
                                width: 16px;
                                height: 16px;
                                background: url(../svgs/calendar.svg) no-repeat;
                                margin-right: 5px;
                            }
                            span {
                                padding-right: 2px;
                                font-style: italic;
                            }
                        }
                        .views {
                            display: flex;
                            width: 50%;
                            align-items: center;
                            justify-content: flex-end;
                            span {
                                padding-left: 5px;
                                padding-right: 5px;
                                font-style: italic;
                            }
                        }
                    }
                    .description{
                        margin-top: 40px;
                        .description-left {
                            .description-text {
                                text-align: justify;
                            }
                            .description-video {
                                width: 100%;
                                margin-top: 20px;
                                video {
                                    max-width: 100%;
                                }
                            }
                            .content-map {
                                width: 100%;
                                h4 {
                                    margin-top: 30px;
                                    margin-bottom: 10px;
                                    font-size: 14px;
                                }
                                .detail-mapReference {
                                    position: relative;
                                    /*.map-reference {
                                        width: 100%;
                                        height: 650px;
                                        height: calc(100% - 56px);
                                        background-color: $color_black;
                                    }*/
                                }
                            }
                            .content-contact {
                                text-align: center;
                                width: 100%;
                                margin-top: 50px;
                                position: fixed;
                                z-index: 2;
                                bottom: 0;
                                width: 100%;
                                left: 0;
                                h5 {
                                    padding-bottom: 35px;
                                    font-weight: 600;
                                    font-size: 28px;
                                    display: none;
                                }
                                .contact-phone {
                                    display: flex;
                                    justify-content: center;
                                    a {
                                        display: inline-block;
                                        text-align: center;
                                        font-weight: 700;
                                        font-size: 1.4rem;
                                        box-shadow: 0 2px 5px #aaa;
                                        color: $color_white;
                                        margin: 0;
                                        padding: 8px;
                                        border-radius: 0;
                                        flex: 1 1 auto;
                                        &.phone {
                                            background: $color_pink;
                                        }
                                        &.whatsapp {
                                            background: #15CB19;
                                        }
                                        &:hover {
                                            text-decoration: underline;
                                        }
                                        svg {
                                            fill: $color_white;
                                            width: 32px;
                                        }
                                    }
                                }                                
                            }
                            h6 {
                                color: $color_pink;
                                margin: 50px 0 18px 0;
                            }
                            
                        }
                        .description-rigth {
                            .mascota-credits {
                                width: 100%;
                                margin-top: 2.5rem;
                                h3 {
                                    font-size: 25px;
                                    color: #363839;
                                    font-weight: 900;
                                    padding-bottom: 2px;
                                    margin-bottom: 10px;
                                    border-bottom: 1px solid $color_pink;
                                    padding-top: 0;
                                    float: left;
                                    width: 100%;
                                    text-align: left;
                                }
                                .mascota-skills {
                                    float: left;
                                    margin-left: 0px;
                                    width: 100%;
                                    .avathar {
                                        img {
                                            width: 100%;
                                            height: auto;
                                            box-shadow: 0 2px 5px #aaa;
                                            border-radius: 8px;
                                        }
                                    }
                                    P {
                                        overflow: hidden;
                                        margin: 8px 0px 4px 0px;
                                        font-size: 12px;
                                        display: flex;
                                        align-items: center;
                                        img {
                                            display: inline-block;
                                            width: 18px;
                                            height: 18px;
                                            margin-right: 4px;
                                        }
                                        b {
                                            color: #9f9f9f;
                                            font-size: 11px;
                                            font-weight: 700;
                                        }
                                        strong {
                                            color: #222222;
                                            font-weight: 800;
                                            font-size: 18px;
                                            margin-left: 10px;
                                        }
                                    }
                                    .share {
                                        display: inline-flex;
                                        gap: 5px;
                                        /* background: red; */
                                        cursor: pointer;
                                        align-items: center;
                                        margin-top: 20px;                                        
                                        background-color: rgba(0, 0, 0, 0.05);
                                        padding: 0 16px;
                                        height: 36px;
                                        font-size: 14px;
                                        line-height: 36px;
                                        border-radius: 18px;
                                        svg {
                                            width: 20px;
                                        }
                                        &:hover {
                                            transition: .2s;
                                            text-decoration: underline; 
                                            text-underline-offset: .4rem; 
                                            text-decoration-color: #038eae;
                                            background-color: rgba(0, 0, 0, 0.1);
                                        }
                                    }
                                }
                                .tags {
                                    margin-left: 0px;
                                    width: 100%;
                                    a {
                                        background-color: $color_pink;
                                        color: #ffffff;
                                        padding: 4px 10px;
                                        display: inline-block;
                                        margin: 2px 1px 4px 1px;
                                        border-radius: 5px;
                                        &:hover {
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                            
                        }
                    }
                }
            }
            .content-important {
                margin-top: 30px;
                h6 {
                    color: $color_pink;
                }
                .important {
                    font-size: 11px;
                    color: #95989c;
                }
            }            
        }
    }
    &.tags {
        .wrapper {
            width: 100%;
            margin-right: auto;
            margin-left: auto;
            max-width: 956px;
            background-color: $color_white;
            box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
        }
    }
}