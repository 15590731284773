@charset "UTF-8";
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  position: relative;
}

body {
  font-family: "Poppins", sans-serif !important;
  background-color: #eaeded;
  height: 100%;
}
body h1, body h2, body h3, body h4, body h5, body h6 {
  font-weight: 700;
}
body h2 {
  font-size: 24px;
  color: #666;
  font-weight: 700;
  position: relative;
  margin-bottom: 10px;
}
body a {
  color: #038eae;
  text-decoration: none;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
body a:hover, body a:focus, body select:hover, body select:focus, body input:hover, body input:focus, body button:hover, body button:focus {
  outline: none;
  box-shadow: none;
}
body ul {
  margin: 0;
  padding-left: 0;
}
body ul li {
  list-style-type: none;
}
body img {
  max-width: 100%;
}
body.page main {
  margin-top: 78px;
}

.bg-white {
  background-color: #fff;
}

hr {
  width: 100%;
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.loading2 {
  position: relative;
  text-align: center;
  height: 265px;
}
.loading2 .loading-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading2 .loading-content .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap;
  clip-path: inset(50%);
}

.loading {
  width: 100%;
}
.loading .spinner {
  height: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  z-index: 100;
  -webkit-animation: heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.map-reference {
  width: 100%;
  background-color: #000;
}

.wha-fixed {
  position: fixed;
  right: 10px;
  top: 50%;
  z-index: 9999;
  background-color: #3fc350;
  padding: 12px 0px 10px 0px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  font-size: 17px;
  border-radius: 50%;
  margin-top: 15px;
  box-shadow: 0 0 8px #000;
  cursor: pointer;
  width: 60px;
  height: 60px;
  transform: translate(0, -50%);
}
.wha-fixed:hover {
  box-shadow: 0 0 8px #c2c2c2;
}
.wha-fixed img {
  width: 35px;
}

/*
.heart {
    display: inline-block;
    position: relative;
    width: 300px;
    height: 300px;
    transform: rotate(45deg);
    //transform-origin: 40px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .heart div {
    //top: 50%;
    //left: 50%;
    //position: absolute;
    //transform: translate(-50%, -50%);
    width: 52px;
    height: 52px;
    background: #ff0302;
    animation: heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .heart div:after,
  .heart div:before {
    content: " ";
    position: absolute;
    display: block;
    width: 52px;
    height: 52px;
    background: #ff0302;
  }
  .heart div:before {
    left: -30px;
    border-radius: 50% 0 0 50%;
  }
  .heart div:after {
    top: -30px;
    border-radius: 50% 50% 0 0;
}
*/
.bg-pink {
  background-color: #ff0167;
}
.bg-pink:hover {
  background-color: #ff0167;
  opacity: 0.8;
}

/*ANIMATIONS
---------------------------------------------------------*/
@-webkit-keyframes head-move {
  50% {
    -webkit-transform: rotate(-1.5deg);
  }
}
@keyframes heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
@keyframes kine {
  to {
    transform: rotate(0deg) scale(1.05);
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0) !important;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  width: 100%;
  position: relative;
  text-align: center;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots li.slick-active button {
  background: #666;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat;
}

/* Icons */
/* Arrows */
.slick-arrow {
  font-family: "Font Awesome 5 Brands";
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  z-index: 1;
  background: rgba(255, 255, 255, 0.9);
  width: 40px;
  height: 71px;
  padding-top: 12px;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: #fff;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-size: 45px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ed0a01;
}

.slick-prev {
  left: -15px;
}

[dir=rtl] .slick-prev {
  right: -8px;
  left: auto;
}

[dir=rtl] .slick-prev:before {
  content: "\f054";
}

.slick-next {
  right: -15px;
}

[dir=rtl] .slick-next {
  right: auto;
  left: -8px;
}

.slick-prev:before {
  content: "";
  background-color: #adadad;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
  height: 30px;
  width: 1px;
  margin-left: 20px;
  margin-top: -9px;
}

.slick-prev:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.slick-next:before {
  content: "";
  background-color: #adadad;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  display: block;
  height: 30px;
  width: 1px;
  margin-left: 20px;
  margin-top: -9px;
}

.slick-next:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.slick-prev:after,
.slick-next:after {
  content: "";
  display: block;
  height: 30px;
  width: 1px;
  background: #adadad;
  margin-left: 20px;
  margin-top: -9px;
}

[dir=rtl] .slick-next:before {
  content: "";
  background-image: url(../images/left.png);
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #ccc;
  border-radius: 50%;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: transparent;
}

header {
  z-index: 3;
}
header a.logo img {
  width: 122px;
}
header nav .btn-search {
  display: none;
  background-color: #ff0167;
  color: #fff;
  border: 1px solid #ff0167;
  border-radius: 25px;
  padding: 2px 15px;
  font-size: 12px;
}
header nav .btn-search svg {
  width: 18px;
  margin-right: 2px;
  fill: #fff;
}
header nav .btn-search:hover, header nav .btn-search.active {
  background-color: transparent;
  border: 1px solid #ff0167;
}
header nav .btn-search:hover span, header nav .btn-search.active span {
  color: #ff0167;
}
header nav .btn-search:hover span svg, header nav .btn-search.active span svg {
  fill: #ff0167;
}
header nav .btn-search:hover span:before, header nav .btn-search.active span:before {
  display: none;
}
header nav .rrss-m {
  display: none;
  flex-direction: column;
  margin-top: 50px;
}
header nav .rrss-m h4 {
  color: #ccc;
  font-size: 18px;
  font-weight: 300;
}
header nav .rrss-m .content-social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
header nav .rrss-m .content-social-media .social-media:not(:last-child) {
  margin-right: 20px;
}
header nav .rrss-m .content-social-media .social-media a svg {
  width: 22px;
  height: 22px;
  fill: #fff;
}
header nav ul.navbar-nav li.nav-item a.nav-link {
  color: #000;
}
header nav ul.navbar-nav li.nav-item a.nav-link span {
  position: relative;
  display: block;
  padding-bottom: 2px;
}
header nav ul.navbar-nav li.nav-item a.nav-link span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background: #ff0167;
  visibility: visible;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
header nav ul.navbar-nav li.nav-item a.nav-link:hover span, header nav ul.navbar-nav li.nav-item a.nav-link.active span {
  color: #ff0167;
}
header nav ul.navbar-nav li.nav-item a.nav-link:hover span:before, header nav ul.navbar-nav li.nav-item a.nav-link.active span:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
}
header nav ul.navbar-nav li.nav-item a.nav-link.active {
  background-color: transparent;
  /*&:hover {
      span {
          &:before {
              display: none;
          }
      }
  }*/
}
header nav ul.navbar-nav li.nav-item a.nav-link.active span {
  color: #ff0167;
}
header nav ul.navbar-nav li.nav-item.search {
  margin-left: 15px;
}
header nav ul.navbar-nav li.nav-item.search a.nav-link {
  background-color: #ff0167;
  color: #fff;
  border: 1px solid #ff0167;
  border-radius: 25px;
  padding: 8px 25px;
}
header nav ul.navbar-nav li.nav-item.search a.nav-link svg {
  width: 22px;
  margin-right: 2px;
  fill: #fff;
}
header nav ul.navbar-nav li.nav-item.search a.nav-link:hover, header nav ul.navbar-nav li.nav-item.search a.nav-link.active {
  background-color: transparent;
  border: 1px solid #ff0167;
}
header nav ul.navbar-nav li.nav-item.search a.nav-link:hover span, header nav ul.navbar-nav li.nav-item.search a.nav-link.active span {
  color: #ff0167;
}
header nav ul.navbar-nav li.nav-item.search a.nav-link:hover span:before, header nav ul.navbar-nav li.nav-item.search a.nav-link.active span:before {
  display: none;
}
header nav ul.navbar-nav li.nav-item.search a.nav-link:hover span svg, header nav ul.navbar-nav li.nav-item.search a.nav-link.active span svg {
  fill: #ff0167;
}
header nav ul.navbar-nav li.nav-item.social-media.first-child {
  margin-left: 30px;
}
header nav ul.navbar-nav li.nav-item.social-media a span svg {
  width: 32px;
  height: 32px;
}
header nav ul.navbar-nav li.nav-item.social-media a:hover span:before, header nav ul.navbar-nav li.nav-item.social-media a.active span:before {
  display: none;
}
header nav ul.navbar-nav li.nav-item.social-media a.fb:hover {
  fill: #038eae;
}
header nav ul.navbar-nav li.nav-item.social-media a.tt:hover {
  fill: #ff0167;
}

/*.header-top {
    position: relative;
    background-color: $color_white;
}*/
body.open {
  overflow-y: hidden;
}
body.open header nav ul.navbar-nav li.nav-item a.nav-link {
  color: #fff;
}
body.open header nav .navbar-collapse {
  background: #000 !important;
  height: 100%;
  height: calc(100% - 53px);
  width: 100%;
  position: fixed;
  top: 53px;
  left: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
body.open .wha-fixed {
  display: none;
}

footer {
  background-color: #000;
  color: #fff;
  padding: 15px 10px;
}
footer .terms a:not(:first-child) span {
  padding-left: 8px;
}
footer .terms a:not(:last-child) span {
  border-right: 1px solid;
}
footer .terms a span {
  padding-right: 12px;
  line-height: 32px;
  color: #fff;
}
footer .terms a:hover span {
  color: #666;
}

.content-video {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}
.content-video iframe, .content-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.leaflet-top.leaflet-right .leaflet-control-locate {
  border-radius: 50%;
}
.leaflet-top.leaflet-right .leaflet-control-locate a {
  border-radius: 50%;
  background-color: #ff0167;
  width: 26px;
  height: 26px;
  position: relative;
}
.leaflet-top.leaflet-right .leaflet-control-locate a span.crosshairs {
  content: "";
  position: absolute;
  background-image: url(../svgs/location.svg);
  width: 18px;
  height: 18px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.div-ejecutado {
  display: flex;
  align-items: center;
  justify-content: center;
}
.div-ejecutado div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.div-ejecutado div.kine {
  transform: rotate(0deg);
  background-image: url(../svgs/marker-macho.svg);
  background-repeat: no-repeat;
  position: relative;
  animation: kine 1s infinite alternate ease-in-out;
  z-index: 2;
}
.div-ejecutado div.kine span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -58%);
  z-index: 344;
}

.div-icon-cluster div {
  width: 100px;
  height: 100px;
}

.leaflet-marker-icon.marker-cluster {
  z-index: 999 !important;
}

.div-icon-cluster {
  position: absolute;
  border-radius: 100%;
  text-align: center;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
  vertical-align: middle;
}

.div-icon-marker-core {
  /*Central Anillo*/
  /*background: #54c8ff;*/
  border-radius: 100%;
  vertical-align: middle;
  font-family: "Segoe UI", Helvetica, sans-serif;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 21px;
  /*border: 1px solid #ccc;*/
}
.div-icon-marker-core .thumbnail {
  font-size: 14px;
}
.div-icon-marker-core .thumbnail img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 50%;
  background-size: 100% auto;
}

.div-icon-marker {
  /*Ultimo Anillo*/
  position: absolute;
  border-radius: 100%;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  padding: 4px;
}
.div-icon-marker:after {
  border: 15px solid;
  content: "";
  left: 50%;
  margin-left: -15px;
  position: absolute;
  bottom: -26px;
}
.div-icon-marker.Hembra {
  background-color: #ff0167;
}
.div-icon-marker.Hembra:after {
  content: "";
  border-color: #ff0167 transparent transparent;
}
.div-icon-marker.Macho {
  background-color: #038eae;
}
.div-icon-marker.Macho:after {
  content: "";
  border-color: #038eae transparent transparent;
}

.div-icon-marker:hover .tooltip-app {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

.div-icon-marker.hover .tooltip-app {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

.div-icon-marker i.icon {
  margin: 0px !important;
}

/*PULSO*/
.pulsex {
  background: rgba(36, 43, 49, 0.3);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: rotateX(55deg);
  z-index: -2;
}

.pulsex:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px rgba(36, 43, 49, 0.5);
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
.tooltip-app {
  text-align: center;
  background: white;
  bottom: 100%;
  color: #626262;
  font-size: 11px;
  display: block;
  /* left: 75px; */
  margin-bottom: 0px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  /* width: 100%; */
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  transform: translate(-50%, 0) !important;
  text-shadow: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  width: 200px;
  /*margin-left:10px;*/
  margin-left: 27px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  /* CSS Triangles - see Trevor's post */
}
.tooltip-app:before {
  content: " ";
  bottom: -10px;
  height: 0;
  left: 50%;
  position: absolute;
  width: 50px;
  height: 10px;
  background-color: white;
  margin-bottom: 10px;
  margin-left: -25px;
  z-index: 2;
}
.tooltip-app:after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
  bottom: -10px;
  content: " ";
  height: 0;
  left: calc(50% - 10px);
  position: absolute;
  filter: drop-shadow(0 0px 5px rgba(0, 0, 0, 0.7));
}
.tooltip-app p:nth-child(1) {
  line-height: 17px;
  font-size: 12px;
  font-weight: bold;
}
.tooltip-app .tooltip-content {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  border-bottom-left-radius: 8px;
  padding-bottom: 14px;
}
.tooltip-app .tooltip-content.Hembra h3 {
  background-color: #ff0167;
  color: #fff;
}
.tooltip-app .tooltip-content.Macho h3 {
  background-color: #038eae;
  color: #fff;
}
.tooltip-app img.img-perfil {
  width: 100%;
  height: 160px;
  object-fit: cover;
  display: block;
}
.tooltip-app img.img-country {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 2px);
  outline: 1px solid #ffffff;
  z-index: 999;
  width: 35px;
  height: auto;
  overflow: hidden;
  object-fit: cover;
}
.tooltip-app h2 {
  font-size: 16px;
  font-weight: bold;
}
.tooltip-app h3 {
  font-size: 20px;
  border-bottom: 1px dashed #038eae;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 3px;
}
.tooltip-app h3:after {
  display: none;
}
.tooltip-app p {
  margin-bottom: 3px;
  padding: 0;
  white-space: nowrap;
  white-space: normal;
  font-size: 12px;
  line-height: normal;
}
.tooltip-app p i {
  color: #038eae;
}
.tooltip-app .content-mascota-popup {
  position: relative;
  background-color: #000;
}
.tooltip-app .content-mascota-popup .img-country {
  width: 35px;
  border: 1px solid #ff0167;
}
.tooltip-app .content-mascota-popup .whatsapp {
  width: 30px;
  height: 30px;
  background: url("/svg/whatsapp.svg") no-repeat;
  position: absolute;
  bottom: 5px;
  left: 5px;
}
.tooltip-app .view-more {
  margin-top: 10px;
}
.tooltip-app .view-more i {
  color: #666;
}
.tooltip-app.Macho {
  border-left: solid 8px #038eae;
}
.tooltip-app.Hembra {
  border-left: solid 8px #666 !important;
}
.tooltip-app.Hembra .estado {
  background: #666 !important;
}
.tooltip-app.Hembra h3 {
  border-bottom: 1px dashed #666 !important;
}
.tooltip-app.Hembra h4 {
  color: #666 !important;
}
.tooltip-app.Hembra p i {
  color: #666 !important;
}
.tooltip-app.Hembra .view-more a {
  color: #666 !important;
}
.tooltip-app.Hembra .view-more a:hover {
  background-color: #666 !important;
}

body.page.red_mascotas main {
  margin-top: 78px;
}
body.page.red_mascotas .section-red {
  padding-top: 25px;
}
body.page.red_mascotas .avathar {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  outline: 8px solid #038eae;
  filter: drop-shadow(0 4px 12px rgba(0, 0, 0, 0.15));
}
body.page.red_mascotas .content-detail .title {
  display: inline-grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 40px;
}
body.page.red_mascotas .content-detail .title h2 {
  color: #000;
}
body.page.red_mascotas .content-detail .title a {
  background-color: #038eae;
  color: #fff;
  border-radius: 8px;
  height: 32px;
  padding: 0 15px;
  text-align: center;
  line-height: 32px;
}
body.page.red_mascotas .content-detail .counts {
  display: inline-grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 40px;
  margin-top: 18px;
}
body.page.red_mascotas .content-detail .phrase {
  margin-top: 25px;
}
body.page.red_mascotas .content-detail .phrase h1 {
  font-size: 14px;
  font-weight: 600;
}
body.page.red_mascotas .content-detail .phrase .link-page:hover {
  text-decoration: underline;
}
body.page.red_mascotas .content-detail .phrase .link-page svg {
  width: 22px;
  fill: #038eae;
}
body.page.red_mascotas .perfil-body {
  margin-top: 25px;
}
body.page.red_mascotas .content-tabs {
  margin-top: 50px;
  margin-bottom: 50px;
}
body.page.red_mascotas .content-tabs .tab-content .card {
  --bs-card-spacer-y: 4px;
  --bs-card-spacer-x: 4px;
}
body.page.red_mascotas .content-tabs .content-thumbnail {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4px;
  position: relative;
}
body.page.red_mascotas .content-tabs .content-thumbnail .thumbnail {
  width: 100%;
  display: block;
  position: relative;
  flex: 1 0 0%;
}
body.page.red_mascotas .content-tabs .content-thumbnail .thumbnail img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
body.page.red_mascotas .content-tabs .content-thumbnail .thumbnail:not(:last-child) {
  margin-right: 4px;
}
body.home section {
  position: relative;
}
body.home section h2 {
  margin-bottom: 40px;
}
body.home section h2 strong {
  color: #038eae;
}
body.home section.section-map {
  width: 100%;
  height: calc(100vh - 100px);
  position: relative;
  overflow: hidden;
}
body.home section.section-map .content-video2 {
  width: 100%;
  height: calc(100vh - 100px);
}
body.home section.section-map .content-video2 iframe {
  width: 100%;
  height: calc(100vh - 100px);
}
body.home section.section-services {
  padding: 50px 0;
  text-align: center;
}
body.home section.section-services .service-content {
  height: 170px;
  display: block;
  text-decoration: none;
  color: #fff;
  border-radius: 10px;
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  -webkit-transform: translateY(-15%);
  -moz-transform: translateY(-15%);
  -ms-transform: translateY(-15%);
  -o-transform: translateY(-15%);
  transform: translateY(-15%);
  display: -webkit-flex;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  opacity: 1;
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
}
body.home section.section-services .service-content .ui-box-icon {
  position: relative;
  bottom: 27px;
}
body.home section.section-services .service-content .ui-box-icon h3 {
  color: rgba(0, 0, 0, 0.3);
  font-size: 18px;
}
body.home section.section-services .service-content .ui-box-icon img {
  width: 44px;
}
body.home section.section-services .service-content .service-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  text-decoration: none;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
body.home section.section-services .service-content .service-item .ui-box {
  max-width: 228px;
  width: 100%;
  text-align: center;
}
body.home section.section-services .service-content .service-item .ui-box p {
  font-family: "Open Sans";
  font-size: 15px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.35);
}
body.home section.section-services .service-content:hover .service-item {
  -webkit-transform: scale(1.15, 1.15);
  -moz-transform: scale(1.15, 1.15);
  -ms-transform: scale(1.15, 1.15);
  -o-transform: scale(1.15, 1.15);
  transform: scale(1.15, 1.15);
  visibility: visible;
  opacity: 1;
}
body.home section.section-services .col-md-3 {
  margin-bottom: 25px;
}
body.home section.section-pet {
  padding: 50px 0;
  text-align: center;
}
body.home section.section-pet .pet__link {
  display: block;
  color: #000;
}
body.home section.section-pet .pet__link--distrito {
  background-color: green;
  color: white;
  line-height: 14px;
  font-size: 12px;
  font-weight: bolder;
  text-align: center;
}
body.home section.section-pet .pet__link img {
  width: 100%;
}
body.home section.section-pet .pet__link h3 {
  margin-top: 4px;
}
body.home section.section-comunidad {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px 0px 50px 0px;
  background-color: #1D3157;
  background-image: url(../images/juntos-iniciemos-este-camino2.jpg);
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
}
body.home section.section-comunidad .asesoria-overlay {
  background-color: transparent;
  background-image: linear-gradient(0deg, #038eae 2%, rgba(56, 125, 255, 0.48) 100%);
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
body.home section.section-comunidad .content-asesoria {
  margin: 10px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
body.home section.section-comunidad .content-asesoria h6 {
  color: #D2E2FF;
  padding-left: 0;
}
body.home section.section-comunidad .content-asesoria p {
  color: #fff;
  padding-left: 0;
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 31px;
}
body.home section.section-comunidad .content-asesoria a {
  background-color: rgba(247, 250, 255, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #D2E2FF;
  box-shadow: 0px 8px 16px -8px rgba(56, 125, 255, 0.5);
  color: #fff;
  display: inline-flex;
  align-items: center;
  padding: 11px 19px;
  width: auto;
}
body.home section.section-comunidad .content-asesoria a span {
  font-weight: 900;
}
body.home section.section-comunidad .content-asesoria a:hover {
  color: #038eae;
  background-color: #fff;
}
body.home section.section-contact {
  position: relative;
  margin-bottom: 100px;
}
body.home section.section-contact .contact-header {
  background-color: #038eae;
  color: #fff;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
}
body.home section.section-contact .contact-header span {
  background-color: rgba(255, 255, 255, 0.16);
  color: #FFFFFF;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
body.home section.section-contact .contact-header span img {
  width: 20px;
}
body.home section.section-contact .contact-header span svg {
  width: 20px;
  height: 20px;
  fill: #ff0167;
}
body.home section.section-contact .contact-header .box-wrapper {
  display: flex;
  margin-bottom: 15px;
}
body.home section.section-contact .contact-header .box-wrapper .box-content {
  padding-left: 12px;
}
body.home section.section-contact .contact-header .box-wrapper .box-content p {
  color: #D2E2FF;
}
body.home section.section-contact .contact-header .box-wrapper .box-content h4 {
  margin-bottom: 2px;
}
body.home section.section-contact .contact-body {
  background-color: #fff;
  box-shadow: 0px 0px 80px -16px rgba(0, 0, 0, 0.16);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  border-radius: 0px 0px 6px 6px;
}
body.home section.section-contact .contact-body h3 {
  margin-bottom: 10px;
}
body.home section.section-contact .contact-body .subtitle {
  margin-bottom: 20px;
}
body.home section.section-contact .contact-body .content-form {
  padding: 40px 15px 24px 15px;
}
body.home section.section-contact .contact-body .content-form .form-control {
  background-color: #F6F6F6;
  border: 1px solid #818a91;
  font-weight: 600;
  min-height: 45px;
}
body.home section.section-contact .contact-body .content-form .row-input {
  margin-bottom: 20px;
}
body.home section.section-contact .contact-body .content-form button {
  background-color: #ff0167;
  padding: 0.7rem 1.2rem;
}
body.home .slider-map-home {
  width: 100%;
  background: rgba(16, 16, 16, 0.1);
  position: absolute;
  bottom: 0;
  z-index: 1024;
  text-align: center;
  max-height: 200px;
  overflow: hidden;
}
body.home .slider-map-home .mascota-item {
  text-align: center;
  display: inline-block;
}
body.home .slider-map-home .mascota-item a {
  display: block;
  overflow: hidden;
  text-align: center;
  height: 200px;
  background-color: #101010;
  position: relative;
}
body.home .slider-map-home .mascota-item a img.img-perfil {
  height: 200px;
  margin: 2px;
  overflow: hidden;
  width: 122px;
  object-fit: cover;
}
body.home .slider-map-home .mascota-item a img.img-country {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 2px);
  outline: 1px solid #ffffff;
  z-index: 999;
  width: 35px;
  height: auto;
  overflow: hidden;
  object-fit: cover;
}
body.home .slider-map-home .mascota-item a .caracteristicas {
  position: relative;
}
body.home .slider-map-home .mascota-item a .caracteristicas .name {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
}
body.home .slider-map-home .mascota-item a .caracteristicas .name.Hembra {
  background: rgba(255, 1, 103, 0.8);
}
body.home .slider-map-home .mascota-item a .caracteristicas .name.Macho {
  background: rgba(3, 142, 174, 0.8);
}
body.home.contact section.section-comunidad {
  background-image: url(../images/contactenos-huella365.jpg);
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
}
body.home.contact section.section-comunidad .asesoria-overlay {
  background-color: transparent;
  background-image: linear-gradient(0deg, #038eae 2%, rgba(56, 125, 255, 0.48) 100%);
}
body.home.contact section.section-comunidad .content-asesoria {
  margin: 0;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  height: 100%;
}
body.home.contact section.section-comunidad .content-asesoria p {
  margin-bottom: 5px;
}
body.home.contact section.section-contact .rrss {
  padding: 0;
  margin-bottom: 15px;
}
body.home.contact section.section-contact .rrss li {
  display: inline-block;
}
body.home.contact section.section-contact .rrss li a {
  display: inline-flex;
  background: #038eae;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
body.home.contact section.section-contact .rrss li a:hover {
  background-color: #000;
}
body.home.contact section.section-contact .rrss li a:hover svg {
  fill: #038eae;
}
body.home.contact section.section-contact .rrss li:not(:last-child) {
  margin-right: 6px;
}
body.home.contact.donate section.section-comunidad {
  background-image: url(../images/banner-huellas365-donaciones.jpg);
}
body.home .frmContacto .row-input {
  position: relative;
}
body.home .frmContacto .row-input .invalid-tooltip {
  position: relative;
  display: inline-block;
  margin-top: 5px;
}

body.legal .bg-legal {
  background-color: #fff;
  padding: 1.25rem;
}
body.legal h3 {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 15px;
}

body.tags h1 {
  margin-top: 50px;
  margin-bottom: 35px;
  font-size: 28px;
  color: #363839;
  padding-top: 20px;
}
body.tags .section-map {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
}
body.tags .section-map .content-video2 {
  width: 100%;
  height: 100%;
}
body.tags .tags .tags-item {
  padding: 7px 0 9px 0;
  color: #000;
  font-size: 14px;
  padding-bottom: 20px;
}
body.tags .tags .tags-item:not(:last-child) {
  border-bottom: solid 1px #DCE8FF;
}
body.tags .tags .tags-item a {
  text-decoration: none;
  transition: 0.2s;
  display: flex;
  position: relative;
}
body.tags .tags .tags-item a:hover {
  background-color: #f5f5f5;
}
body.tags .tags .tags-item a .content-thumbnail {
  margin-top: 10px;
  text-align: center;
  position: relative;
  display: flex;
}
body.tags .tags .tags-item a .content-thumbnail .thumbnail {
  position: relative;
  overflow: hidden;
  display: flex;
  border: 1px solid #cccccc;
  -webkit-box-shadow: 0 8px 10px -6px #999;
  -moz-box-shadow: 0 8px 10px -6px #999;
  box-shadow: 0 8px 10px -6px #666;
}
body.tags .tags .tags-item a .content-thumbnail .thumbnail img {
  height: 150px;
  margin: 2px;
  overflow: hidden;
  width: 122px;
  object-fit: cover;
  cursor: pointer;
}
body.tags .tags .tags-item a .content-thumbnail .thumbnail span {
  position: absolute;
  transform: rotate(45deg);
  right: -35px;
  font-size: 10px;
  text-align: center;
  background-color: #ff0167;
  color: #fff;
  top: 14px;
  width: 126px;
  z-index: 1;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  line-height: 30px;
}
body.tags .tags .tags-item a .resumen {
  margin-top: 8px;
  font-size: 14px;
  color: #0033CC;
  width: 86%;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}
body.tags .tags .tags-item a .resumen h3 {
  display: block;
  font-weight: 600;
  font-size: 20px;
  color: #ff0167;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}
body.tags .tags .tags-item a .resumen .texto_descripcion {
  float: left;
  width: 100%;
  line-height: 20px;
  font-size: small;
  font-weight: normal;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
}
body.tags .tags .tags-item a .resumen .published {
  color: #000;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  float: left;
}

.modal.modal-share .modal-header, .modal.modal-share .modal-footer {
  border: 0;
}
.modal.modal-share .modal-header button {
  transition: transform 100ms cubic-bezier(0, 0, 0.38, 0.9) 400ms;
  position: absolute;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  align-items: center;
  border: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  background: #fff;
  right: 15px;
  text-align: center;
}
.modal.modal-share .modal-header button span {
  width: 24px;
  height: 24px;
  margin: auto;
}
.modal.modal-share form {
  display: flex;
}
.modal.modal-share form input {
  border-right: 0;
  flex: 1;
  height: 48px;
  border: 1px solid #2d2f31;
  border-radius: 0;
  -webkit-appearance: none;
  display: block;
  padding: 0 16px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
}
.modal.modal-share form button {
  background-color: #2d2f31;
  color: #fff;
  position: relative;
  align-items: center;
  border: none;
  cursor: pointer;
  display: inline-flex;
  min-width: 100px;
  padding: 0 1.2rem;
  justify-content: center;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0;
  font-size: 16px;
  border-radius: unset;
}
.modal.modal-share form button:hover {
  background-color: #3e4143;
}
.modal.modal-share ul.share-rrss {
  padding-left: 0;
}
.modal.modal-share ul.share-rrss li {
  display: inline-block;
  width: 64px;
  text-align: center;
}
.modal.modal-share ul.share-rrss li a {
  display: inline-block;
}
.modal.modal-share ul.share-rrss li a span {
  border: 1px solid #2d2f31;
  width: 48px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.modal.modal-share ul.share-rrss li a span svg {
  width: 18px;
  height: 18px;
}
.modal.modal-share ul.share-rrss li a div {
  font-size: 12px;
  margin-top: 5px;
  color: #000;
}
.modal.modal-share ul.share-rrss li a:hover span {
  background-color: rgba(3, 142, 174, 0.12);
}
.modal.modal-share ul.share-rrss li a:hover div {
  color: #038eae;
}

.huellas-toast {
  text-align: center;
  justify-content: center;
  display: flex;
  color: #fff;
  width: 100%;
  pointer-events: none;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
}
.huellas-toast .huellas-toast-notice {
  background: rgba(8, 7, 7, 0.9);
  position: fixed;
  padding: 10px;
  border-radius: 8px;
  z-index: 1056;
  bottom: 15px;
  left: 15px;
}
.huellas-toast .huellas-toast-notice h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.huellas-toast .huellas-toast-notice.move-in {
  display: block !important;
  -webkit-animation-name: MessageMoveIn;
  animation-name: MessageMoveIn;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}
.huellas-toast .huellas-toast-notice.move-out {
  display: block !important;
  -webkit-animation-name: MessageMoveOut;
  animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}
.huellas-toast .huellas-toast-notice h4 {
  margin: 0;
  padding: 5px;
}

body.login {
  background-color: #fff;
}
body.login:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 638px;
  height: 345px;
  background-image: url(../images/bg-huellas-top.png);
  background-repeat: no-repeat;
}
body.login:after {
  content: "";
  position: fixed;
  bottom: 0;
  left: 0;
  width: 843px;
  height: 449px;
  background-image: url(../images/bg-huellas-bottom.png);
  background-repeat: no-repeat;
}
body.login section {
  position: relative;
}
body.login section.section-login {
  position: relative;
  z-index: 1;
  margin-top: 150px;
}
body.login section.section-login h1 {
  margin: 50px 16px;
  font-size: 46px;
  line-height: 1.21;
  letter-spacing: normal;
  color: #2e2c36;
  font-weight: bold;
}
body.login section.section-login .content-login {
  background: #eeeeee;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  padding: 3rem !important;
  max-width: 480px;
  margin: 0 auto;
  border-radius: 15px;
}
body.login section.section-login .content-rrss .btn-login {
  width: 100%;
  max-width: 325px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: #ff0167;
  color: #fff;
  appearance: none !important;
  transition: 0.2s;
}
body.login section.section-login .content-rrss .btn-login svg {
  width: 25px;
}
body.login section.section-login .content-rrss .btn-login span {
  font-size: 16px;
  font-weight: bold;
  padding-left: 10px;
}
body.login section.section-login .content-rrss .btn-login:hover {
  background-color: #038eae;
}
body.login section.section-login .content-video {
  overflow: hidden;
  border-radius: 15px;
}

body.detail .wrapper {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 956px;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
body.detail .wrapper section.section-detail {
  padding-bottom: 50px;
}
body.detail .wrapper section.section-detail h1 {
  margin-top: 0;
  margin-bottom: 15px;
  padding-top: 0;
  text-align: center;
  font-size: 21px;
  font-weight: 600;
}
body.detail .wrapper section.section-detail h2 {
  font-size: 38px;
  font-weight: 900;
  color: #ff0167;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  padding-top: 20px;
}
body.detail .wrapper section.section-detail .ubigeo {
  text-align: center;
  margin-bottom: 10px;
}
body.detail .wrapper section.section-detail .ubigeo a {
  display: inline-block;
  font-size: 13px;
  white-space: nowrap;
  padding: 4px 8px 5px 8px;
  border-radius: 3px;
  color: #2A5279;
  background-color: #DEF2FC;
  margin: 4px;
  text-overflow: ellipsis;
}
body.detail .wrapper section.section-detail .slider-mascota {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
body.detail .wrapper section.section-detail .slider-mascota .slider-big img {
  height: 300px;
  object-fit: cover;
  width: auto;
  max-width: 100%;
  z-index: 2;
  position: relative;
}
body.detail .wrapper section.section-detail .slider-mascota .slider-big .slick-list {
  background-image: url(../images/bg-huellas365-slider.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-color: #222222;
}
body.detail .wrapper section.section-detail .slider-mascota .slider-small img {
  width: 80px;
  height: 60px;
  overflow: hidden;
  object-fit: cover;
  cursor: pointer;
  opacity: 0.6;
}
body.detail .wrapper section.section-detail .slider-mascota .slider-small .bg-mascota {
  display: inline-block;
  background-color: #fff;
}
body.detail .wrapper section.section-detail .slider-mascota .slider-small .bg-mascota:hover img {
  opacity: 1 !important;
}
body.detail .wrapper section.section-detail .slider-mascota .slider-small .slick-active.slick-current.slick-center img {
  opacity: 1 !important;
}
body.detail .wrapper section.section-detail .slider-mascota .slider-small-bottom {
  background-color: #222222;
  position: relative;
  padding: 10px 0;
  width: 100%;
}
body.detail .wrapper section.section-detail .mascota-date {
  display: flex;
  background: #f2f2f2;
  min-height: 52px;
  padding: 0 15px;
}
body.detail .wrapper section.section-detail .mascota-date .published {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
}
body.detail .wrapper section.section-detail .mascota-date .published i {
  width: 16px;
  height: 16px;
  background: url(../svgs/calendar.svg) no-repeat;
  margin-right: 5px;
}
body.detail .wrapper section.section-detail .mascota-date .published span {
  padding-right: 2px;
  font-style: italic;
}
body.detail .wrapper section.section-detail .mascota-date .views {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-end;
}
body.detail .wrapper section.section-detail .mascota-date .views span {
  padding-left: 5px;
  padding-right: 5px;
  font-style: italic;
}
body.detail .wrapper section.section-detail .description {
  margin-top: 40px;
}
body.detail .wrapper section.section-detail .description .description-left .description-text {
  text-align: justify;
}
body.detail .wrapper section.section-detail .description .description-left .description-video {
  width: 100%;
  margin-top: 20px;
}
body.detail .wrapper section.section-detail .description .description-left .description-video video {
  max-width: 100%;
}
body.detail .wrapper section.section-detail .description .description-left .content-map {
  width: 100%;
}
body.detail .wrapper section.section-detail .description .description-left .content-map h4 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 14px;
}
body.detail .wrapper section.section-detail .description .description-left .content-map .detail-mapReference {
  position: relative;
  /*.map-reference {
      width: 100%;
      height: 650px;
      height: calc(100% - 56px);
      background-color: $color_black;
  }*/
}
body.detail .wrapper section.section-detail .description .description-left .content-contact {
  text-align: center;
  width: 100%;
  margin-top: 50px;
  position: fixed;
  z-index: 2;
  bottom: 0;
  width: 100%;
  left: 0;
}
body.detail .wrapper section.section-detail .description .description-left .content-contact h5 {
  padding-bottom: 35px;
  font-weight: 600;
  font-size: 28px;
  display: none;
}
body.detail .wrapper section.section-detail .description .description-left .content-contact .contact-phone {
  display: flex;
  justify-content: center;
}
body.detail .wrapper section.section-detail .description .description-left .content-contact .contact-phone a {
  display: inline-block;
  text-align: center;
  font-weight: 700;
  font-size: 1.4rem;
  box-shadow: 0 2px 5px #aaa;
  color: #fff;
  margin: 0;
  padding: 8px;
  border-radius: 0;
  flex: 1 1 auto;
}
body.detail .wrapper section.section-detail .description .description-left .content-contact .contact-phone a.phone {
  background: #ff0167;
}
body.detail .wrapper section.section-detail .description .description-left .content-contact .contact-phone a.whatsapp {
  background: #15CB19;
}
body.detail .wrapper section.section-detail .description .description-left .content-contact .contact-phone a:hover {
  text-decoration: underline;
}
body.detail .wrapper section.section-detail .description .description-left .content-contact .contact-phone a svg {
  fill: #fff;
  width: 32px;
}
body.detail .wrapper section.section-detail .description .description-left h6 {
  color: #ff0167;
  margin: 50px 0 18px 0;
}
body.detail .wrapper section.section-detail .description .description-rigth .mascota-credits {
  width: 100%;
  margin-top: 2.5rem;
}
body.detail .wrapper section.section-detail .description .description-rigth .mascota-credits h3 {
  font-size: 25px;
  color: #363839;
  font-weight: 900;
  padding-bottom: 2px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ff0167;
  padding-top: 0;
  float: left;
  width: 100%;
  text-align: left;
}
body.detail .wrapper section.section-detail .description .description-rigth .mascota-credits .mascota-skills {
  float: left;
  margin-left: 0px;
  width: 100%;
}
body.detail .wrapper section.section-detail .description .description-rigth .mascota-credits .mascota-skills .avathar img {
  width: 100%;
  height: auto;
  box-shadow: 0 2px 5px #aaa;
  border-radius: 8px;
}
body.detail .wrapper section.section-detail .description .description-rigth .mascota-credits .mascota-skills P {
  overflow: hidden;
  margin: 8px 0px 4px 0px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
body.detail .wrapper section.section-detail .description .description-rigth .mascota-credits .mascota-skills P img {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 4px;
}
body.detail .wrapper section.section-detail .description .description-rigth .mascota-credits .mascota-skills P b {
  color: #9f9f9f;
  font-size: 11px;
  font-weight: 700;
}
body.detail .wrapper section.section-detail .description .description-rigth .mascota-credits .mascota-skills P strong {
  color: #222222;
  font-weight: 800;
  font-size: 18px;
  margin-left: 10px;
}
body.detail .wrapper section.section-detail .description .description-rigth .mascota-credits .mascota-skills .share {
  display: inline-flex;
  gap: 5px;
  /* background: red; */
  cursor: pointer;
  align-items: center;
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0 16px;
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  border-radius: 18px;
}
body.detail .wrapper section.section-detail .description .description-rigth .mascota-credits .mascota-skills .share svg {
  width: 20px;
}
body.detail .wrapper section.section-detail .description .description-rigth .mascota-credits .mascota-skills .share:hover {
  transition: 0.2s;
  text-decoration: underline;
  text-underline-offset: 0.4rem;
  text-decoration-color: #038eae;
  background-color: rgba(0, 0, 0, 0.1);
}
body.detail .wrapper section.section-detail .description .description-rigth .mascota-credits .tags {
  margin-left: 0px;
  width: 100%;
}
body.detail .wrapper section.section-detail .description .description-rigth .mascota-credits .tags a {
  background-color: #ff0167;
  color: #ffffff;
  padding: 4px 10px;
  display: inline-block;
  margin: 2px 1px 4px 1px;
  border-radius: 5px;
}
body.detail .wrapper section.section-detail .description .description-rigth .mascota-credits .tags a:hover {
  text-decoration: underline;
}
body.detail .wrapper .content-important {
  margin-top: 30px;
}
body.detail .wrapper .content-important h6 {
  color: #ff0167;
}
body.detail .wrapper .content-important .important {
  font-size: 11px;
  color: #95989c;
}
body.tags .wrapper {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 956px;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

@media (min-width: 576px) {
  body.detail .wrapper section.section-detail .description .description-left .content-contact {
    position: relative;
  }
  body.detail .wrapper section.section-detail .description .description-left .content-contact h5 {
    display: block;
  }
  body.detail .wrapper section.section-detail .description .description-left .content-contact .contact-phone a {
    padding: 8px 40px;
    margin: 2px;
    border-radius: 5px;
    flex: unset;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    justify-content: flex-end;
  }

  body.detail .wrapper .content-important {
    margin-top: 0;
  }
}
@media screen and (min-width: 992px) {
  body.home section.section-comunidad {
    padding: 80px 0px 80px 0px;
    background-size: 50vw auto;
  }
  body.home section.section-comunidad .asesoria-overlay {
    background-image: linear-gradient(270deg, #038eae 50%, rgba(56, 125, 255, 0.48) 100%);
  }
  body.home section.section-comunidad .content-asesoria {
    margin: 0% 0% 0% 50%;
  }
  body.home section.section-comunidad .content-asesoria p {
    font-size: 32px;
    line-height: 36px;
  }
  body.home section.section-contact .contact-header {
    flex-direction: row;
  }
  body.home section.section-contact .contact-header span {
    width: 64px;
    height: 64px;
  }
  body.home section.section-contact .contact-header span svg, body.home section.section-contact .contact-header span img {
    width: 32px;
    height: 32px;
  }
  body.home section.section-contact .contact-header .box-wrapper {
    margin-bottom: 0;
  }
  body.home section.section-contact .contact-header .box-wrapper .box-content h4 {
    margin-bottom: 0.5rem;
  }
  body.home section.section-contact .contact-body .content-form {
    padding: 40px 40px 24px 40px;
  }
  body.home.contact section.section-comunidad {
    height: 400px;
  }
  body.detail .wrapper section.section-detail h2 {
    font-size: 90px;
  }
  body.detail .wrapper section.section-detail .slider-mascota .slider-big img {
    height: 600px;
  }
  body.detail .wrapper section.section-detail .slider-mascota .slider-small img {
    width: 120px;
    height: 80px;
  }
  body.detail .wrapper section.section-detail .description .description-rigth {
    padding-left: 27px;
  }
  body.detail .wrapper section.section-detail .description .description-rigth .mascota-credits {
    margin-top: 0;
  }
}
@media (min-width: 1400px) {
  body.tags .section-map {
    height: 500px;
  }
  body.home.contact section.section-comunidad {
    height: 500px;
  }
}
@media (max-width: 767px) {
  body.page main {
    margin-top: 53px;
  }
  body header nav.navbar {
    padding: 0;
  }
  body header nav.navbar a.logo img {
    width: 100px;
  }
  body header nav.navbar ul.navbar-nav li.nav-item.search {
    display: none;
  }
  body header nav.navbar ul.navbar-nav li.nav-item.social-media {
    display: none;
  }
  body header nav.navbar .rrss-m {
    display: flex;
  }
  body header nav.navbar .btn-search {
    display: inline-block;
  }
}
@media (max-width: 576px) {
  body.home .slider-map-home {
    max-height: 150px;
  }
  body.home .slider-map-home .mascota-item a {
    height: 150px;
  }
  body.home .slider-map-home .mascota-item a img.img-perfil {
    height: 150px;
  }
  body.home .slider-map-home .mascota-item a img.img-country {
    width: 30px;
  }
  body.home .slider-map-home .mascota-item a .caracteristicas .name {
    font-size: 12px;
  }

  .modal.modal-share {
    top: auto;
    bottom: 0px;
    height: auto;
    overflow: unset;
  }
  .modal.modal-share .modal-dialog {
    margin: 0;
  }
  .modal.modal-share .modal-dialog .modal-content, .modal.modal-share .modal-dialog .modal-header {
    border-radius: unset;
  }
  .modal.modal-share .modal-dialog .modal-content {
    border: unset;
  }
  .modal.modal-share .modal-dialog .modal-header {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .modal.modal-share .modal-dialog .modal-header .fs-5 {
    font-size: 18px !important;
  }
  .modal.modal-share .modal-dialog .modal-header button {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
    top: -24px;
  }
  .modal.modal-share .modal-dialog .modal-body {
    padding-top: 2px;
    padding-bottom: 0;
  }
  .modal.modal-share .modal-dialog .modal-body form input {
    height: 40px;
    font-size: 14px;
  }
  .modal.modal-share .modal-dialog .modal-body form button {
    font-size: 14px;
    width: 80px;
    min-width: auto;
  }
  .modal.modal-share .modal-dialog .modal-footer ul.share-rrss li a span {
    width: 44px;
    height: 44px;
  }

  .huellas-toast .huellas-toast-notice {
    left: auto;
  }

  hide {
    display: none;
  }

  .video_tiktok blockquote {
    max-width: 100% !important;
  }

  .video_youtube {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
  }
  .video_youtube iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}