
body {
    &.page {
        &.red_mascotas {
            main {
                margin-top: 78px;
            }
            .section-red {
                padding-top: 25px;
            }
            .avathar {
                width: 150px;
                height: 150px;
                object-fit: cover;
                border-radius: 50%;
                outline: 8px solid $color_cyan;
                filter: drop-shadow(0 4px 12px rgba($color_black, .15));
            }
            .content-detail {
                .title {
                    display: inline-grid;
                    gap: 10px;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: 40px;
                    h2 {
                        color: $color_black;
                    }
                    a {
                        background-color: $color_cyan;
                        color: $color_white;
                        border-radius: 8px;
                        height: 32px;
                        padding: 0 15px;
                        text-align: center;
                        line-height: 32px;
                    }
                }
                .counts {
                    display: inline-grid;
                    gap: 10px;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: 40px;
                    margin-top: 18px;
                }
                .phrase {
                    margin-top: 25px;
                    h1 {
                        font-size: 14px;
                        font-weight: 600;
                    }
                    .link-page {
                        &:hover {
                            text-decoration: underline;
                        }
                        svg {
                            width: 22px;
                            fill: $color_cyan;
                        }
                    }
                }
                
            }
            .perfil-body {
                margin-top: 25px;
            }
            .content-tabs {
                margin-top: 50px;
                margin-bottom: 50px;
                .tab-content {
                    .card {
                        --bs-card-spacer-y: 4px;
                        --bs-card-spacer-x: 4px;
                    }
                }
                .content-thumbnail {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-gap: 4px;
                    position: relative;
                    .thumbnail {
                        width: 100%;
                        display: block;
                        position: relative;
                        flex: 1 0 0%;
                        img {
                            width: 100%;
                            height: 300px;
                            object-fit: cover;
                        }
                        &:not(:last-child) {
                            margin-right: 4px;
                        }
                    }
                }
            }
        }
    }
	&.home {
		section {
            position: relative;
			h2 {
				strong {
					color: $color_cyan;
				}
				margin-bottom: 40px;
			}
            &.section-map {
                width: 100%;
                //height: 280px;
                height: calc(100vh - 100px);
                position: relative;
                overflow: hidden;
                .content-video2 {
                    width: 100%;
                    //height: 280px;
                    height: calc(100vh - 100px);
                    iframe {
                        width: 100%;
                        //height: 280px;
                        height: calc(100vh - 100px);
                    }
                }
            }
            &.section-services {
                padding: 50px 0;
				text-align: center;
                .service-content {
                    height: 170px;
                    display: block;
                    text-decoration: none;
                    color: #fff;
                    border-radius: 10px;
                    position: relative;
                    background-color: #fff;
                    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, .3);
                    -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
                    -ms-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
                    -o-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, .3);
                    -webkit-transition: all .5s linear;
                    -moz-transition: all .5s linear;
                    -ms-transition: all .5s linear;
                    -o-transition: all .5s linear;
                    transition: all .5s linear;
                    -webkit-transform: translateY(-15%);
                    -moz-transform: translateY(-15%);
                    -ms-transform: translateY(-15%);
                    -o-transform: translateY(-15%);
                    transform: translateY(-15%);
                    display: -webkit-flex;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    opacity: 1;
                    -webkit-transform: translateY(-0%);
                    -moz-transform: translateY(-0%);
                    -ms-transform: translateY(-0%);
                    -o-transform: translateY(-0%);
                    transform: translateY(-0%);
                    .ui-box-icon {
                        position: relative;
                        bottom: 27px;
                        h3 {
                            color: rgba(0,0,0,0.30);
                            font-size: 18px;
                        }
                        img {
                            width: 44px;
                        }
                    }
                    .service-item {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        color: #fff;
                        text-decoration: none;
                        border-radius: 10px;
                        visibility: hidden;
                        opacity: 0;
                        background-color: #fff;
                        -webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, .1);
                        -moz-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
                        -ms-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
                        -o-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
                        box-shadow: 0px 0px 30px rgba(0, 0, 0, .1);
                        z-index: 1;
                        display: -webkit-flex;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        -webkit-transition: all .5s linear;
                        -moz-transition: all .5s linear;
                        -ms-transition: all .5s linear;
                        -o-transition: all .5s linear;
                        transition: all .5s linear;
                        .ui-box {
                            max-width: 228px;
                            width: 100%;
                            text-align: center;
                            p  {
                                font-family: "Open Sans";
                                font-size: 15px;
                                line-height: 19px;
                                color: rgba(0, 0, 0, 0.35);
                            }
                        }
                    }
                    &:hover {
                        .service-item {
                            -webkit-transform: scale(1.15,1.15);
                            -moz-transform: scale(1.15,1.15);
                            -ms-transform: scale(1.15,1.15);
                            -o-transform: scale(1.15,1.15);
                            transform: scale(1.15,1.15);
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
                .col-md-3 {
                    margin-bottom: 25px;
                }
            }
            &.section-pet {
                padding: 50px 0;
                text-align: center;
                .pet {
                    &__link {
                        display: block;
                        color: $color_black;
                        &--distrito {
                            background-color: green;
                            color: white;
                            line-height: 14px;
                            font-size: 12px;
                            font-weight: bolder;
                            text-align: center;
                        }
                        img {
                            width: 100%;
                        }
                        h3 {
                            margin-top: 4px
                        }
                    }                    
                }               
            }
            &.section-comunidad{
                transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
                padding: 50px 0px 50px 0px;
                background-color: #1D3157;
                background-image: url(../images/juntos-iniciemos-este-camino2.jpg);
                background-position: center left;
                background-repeat: no-repeat;
                background-size: cover;
                .asesoria-overlay {
                    background-color: transparent;
                    background-image: linear-gradient(0deg, $color_cyan 2%, rgba(56, 125, 255, 0.48) 100%);
                    opacity: 1;
                    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    position: absolute;
                }
                .content-asesoria {
                    //width: 100%;
                    margin: 10px;
                    position: relative;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    h6 {
                        color: #D2E2FF;
                        padding-left: 0;
                    }
                    p {
                        color: $color_white;
                        padding-left: 0;
                        font-size: 22px;
                        line-height: 26px;
                        font-weight: 700;
                        margin-bottom: 31px;
                    }
                    a {
                        background-color: rgba(247, 250, 255, 0);
                        border-style: solid;
                        border-width: 1px 1px 1px 1px;
                        border-color: #D2E2FF;
                        box-shadow: 0px 8px 16px -8px rgba(56, 125, 255, .5);
                        color: $color_white;
                        display: inline-flex;
                        align-items: center;
                        padding: 11px 19px;
                        width: auto;
                        span {
                            font-weight: 900;
                        }
                        &:hover {
                            color: $color_cyan;
                            background-color: $color_white;
                        }
                    }
                }
            }
            &.section-contact {
                position: relative;
                margin-bottom: 100px;
                .contact-header {
                    background-color: $color_cyan;
                    color: $color_white;
                    border-radius: 6px 6px 0px 0px;
                    display:flex;
                    justify-content: space-between;
                    flex-direction: column;
                    padding: 40px;
                    span {
                        background-color: rgba(255, 255, 255, 0.16);
                        color: #FFFFFF;
                        -webkit-border-radius: 50%;
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 20px;
                        }
                        svg {
                            width: 20px;
                            height: 20px;
                            fill: $color_pink;
                        }
                    }
                    .box-wrapper {
                        display:flex;
                        margin-bottom: 15px;
                        .box-content {
                            padding-left: 12px;
                            p {
                                color: #D2E2FF;
                            }
                            h4 {
                                margin-bottom: 2px;
                            }
                        }
                    }
                }
                .contact-body {
                    background-color: $color_white;
                    box-shadow: 0px 0px 80px -16px rgba(0, 0, 0, .16);
                    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
                    border-radius: 0px 0px 6px 6px;
                    h3 {
                        margin-bottom: 10px;
                    }
                    .subtitle {
                        margin-bottom: 20px;
                    }
                    .content-form {
                        padding: 40px 15px 24px 15px;
                        .form-control {                            
                            background-color: #F6F6F6;
                            border: 1px solid #818a91;
                            font-weight: 600;
                            min-height: 45px;
                        }
                        .row-input {
                            margin-bottom: 20px;
                        }
                        button {
                            background-color: $color_pink;
                            padding: .7rem 1.2rem;
                        }
                    }
                }
            }
        }
        .slider-map-home {
            width: 100%;
            background: rgba(16,16,16,.1);
            position: absolute;
            bottom: 0;
            z-index: 1024;
            text-align: center;
            max-height: 200px;
            overflow: hidden;
            .mascota-item{
                text-align: center;
                display: inline-block;
                a {
                    display: block;
                    overflow: hidden;
                    text-align: center;
                    height: 200px;
                    background-color: #101010;
                    position: relative;
                    img{
                        &.img-perfil {
                            height: 200px;
                            margin: 2px;
                            overflow: hidden;
                            width: 122px;
                            object-fit: cover;
                        }
                        &.img-country {
                            position: absolute;
                            top: 0;
                            left: 50%;
                            transform: translate(-50%, 2px);
                            outline: 1px solid #ffffff;
                            z-index: 999;
                            width: 35px;
                            height: auto;
                            overflow: hidden;
                            object-fit: cover;
                        }
                    }
                    
                    .caracteristicas {
                        position: relative;
                        // .estado {
                        //     position: absolute;
                        //     text-align: center;
                        //     transform: rotate(45deg);
                        //     right: -35px;
                        //     text-align: center;
                        //     background-color: #666;
                        //     color: #fff;
                        //     top: 14px;
                        //     width: 126px;
                        //     z-index: 1;
                        //     box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
                        //     line-height: 30px;
                        //     font-size: 10px;
                        // }
                        
                        .name {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            color: $color_white;
                            &.Hembra {
                                background: rgba($color_pink, .8);
                            }
                            &.Macho {
                                background: rgba($color_cyan, .8);
                            }                        
                        }
                    }
                }
            }
        }
        &.contact {
            section {
                &.section-comunidad {
                    background-image: url(../images/contactenos-huella365.jpg);
                    background-attachment: fixed;
                    background-position: center center;
                    background-size: cover;
                    .asesoria-overlay {
                        background-color: transparent;
                        background-image: linear-gradient(0deg, $color_cyan 2%, rgba(56, 125, 255, 0.48) 100%);
                    }
                    .content-asesoria {
                        margin: 0;
                        text-align: center;
                        justify-content: center;
                        flex-direction: column;
                        display: flex;
                        height: 100%;
                        p {
                            margin-bottom: 5px;
                        }
                    }
                }
                &.section-contact {
                    .rrss {
                        padding: 0;
                        margin-bottom: 15px;
                        li {
                            display: inline-block;
                            a {
                                display: inline-flex;
                                background: $color_cyan;
                                width: 32px;
                                height: 32px;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                                &:hover {
                                    background-color: $color_black;
                                    svg {
                                        fill: $color_cyan;
                                    }
                                }
                            }
                            &:not(:last-child) {
                                margin-right: 6px;
                            }
                        }
                    }
                }
            }           
            &.donate {
                section {
                    &.section-comunidad {
                        background-image: url(../images/banner-huellas365-donaciones.jpg);
                    }
                }           
                
            }
        }
        .frmContacto {
            .row-input {
                position: relative;
                .invalid-tooltip {
                    position: relative;
                    display: inline-block;
                    margin-top: 5px;
                }
            }
        }
        
	}

}
