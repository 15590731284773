/*ANIMATIONS
---------------------------------------------------------*/

@-webkit-keyframes head-move {
    50% { 
      -webkit-transform: rotate(-1.5deg); 
    }
}

@keyframes heart {
    0% {
      transform: scale(0.95);
    }
    5% {
      transform: scale(1.1);
    }
    39% {
      transform: scale(0.85);
    }
    45% {
      transform: scale(1);
    }
    60% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(0.9);
    }
}

@keyframes kine{
	to{
		transform: rotate(0deg) scale(1.05);
	}
}