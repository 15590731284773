.huellas-toast {
    text-align: center;
    justify-content: center;
    display: flex;
    color: #fff;
    width: 100%;
    pointer-events: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    .huellas-toast-notice {
        background: rgba(8, 7, 7, .9);
        position: fixed;
        padding: 10px;
        border-radius: 8px;
        z-index: 1056;
        bottom: 15px;
        left: 15px;
        h4 {
            font-family: $font_family_alt;
            font-size: 14px;
            font-weight: 400;
        }
        &.move-in {
            display: block !important;
            -webkit-animation-name:MessageMoveIn;
            animation-name:MessageMoveIn;
            -webkit-animation-duration:.3s;
            animation-duration: .3s;
        }
        &.move-out {
            display: block !important;
            -webkit-animation-name:MessageMoveOut;
            animation-name:MessageMoveOut;
            -webkit-animation-duration:.3s;
            animation-duration: .3s;
        }
        h4 {
            margin: 0;
            padding: 5px;
        }
    }
}
