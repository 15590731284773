body {
    &.tags {
        h1 {
            margin-top: 50px;
            margin-bottom: 35px;
            font-size: 28px;
            color: #363839;
            padding-top: 20px;
        }
        .section-map {
            width: 100%;
            height: 300px;
            //height: calc(300vh - 100px);
            position: relative;
            overflow: hidden;
            .content-video2 {
                width: 100%;
                //height: calc(100vh - 100px);
                height: 100%;
            }
        }
        .tags {
            .tags-item {
                padding: 7px 0 9px 0;
                color: #000;
                font-size: 14px;
                padding-bottom: 20px;
                &:not(:last-child) {
                    border-bottom: solid 1px #DCE8FF;
                }
                a {
                    text-decoration: none;
                    transition: .2s;
                    display: flex;
                    position: relative;
                    &:hover {
                        background-color: #f5f5f5;
                    }
                    .content-thumbnail {
                        margin-top: 10px;
                        text-align: center;
                        position: relative;
                        display: flex;
                        .thumbnail {
                            position: relative;
                            overflow: hidden;
                            display: flex;
                            border: 1px solid #cccccc;
                            -webkit-box-shadow: 0 8px 10px -6px #999;
                            -moz-box-shadow: 0 8px 10px -6px #999;
                            box-shadow: 0 8px 10px -6px #666;
                            img {
                                height: 150px;
                                margin: 2px;
                                overflow: hidden;
                                width: 122px;
                                object-fit: cover;
                                cursor: pointer;
                            }
                            span {
                                position: absolute;
                                transform: rotate(45deg);
                                right: -35px;
                                font-size: 10px;
                                text-align: center;
                                background-color: $color_pink;
                                color: $color_white;
                                top: 14px;
                                width: 126px;
                                z-index: 1;
                                box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
                                line-height: 30px;
                            }
                        }
                    }
                    .resumen {
                        margin-top: 8px;
                        font-size: 14px;
                        color: #0033CC;
                        width: 86%;
                        display: flex;
                        flex-direction: column;
                        padding-left: 15px;
                        h3 {
                            display: block;
                            font-weight: 600;
                            font-size: 20px;
                            color: $color_pink;
                            margin-bottom: 10px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-box-orient: vertical;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                        }
                        .texto_descripcion {
                            float: left;
                            width: 100%;
                            line-height: 20px;
                            font-size: small;
                            font-weight: normal;
                            color: black;

                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-box-orient: vertical;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                        }
                        .published {
                            color: $color_black;
                            font-size: 12px;
                            position: absolute;
                            bottom: 0;
                            float: left;
                        }
                    }
                }
            }
        } 
    }
}