footer {
	background-color: $color_black;
    color: $color_white;
    padding: 15px 10px;
    .terms {
        a {
            &:not(:first-child) span {
                padding-left: 8px;
            }
            &:not(:last-child) span {
                border-right: 1px solid;
            }
            span {
                padding-right: 12px;
                line-height: 32px;
                color: $color_white;
            }
            &:hover {
                span {
                    color: #666;
                }
            }
        }
        
    }
    
}
