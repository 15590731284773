.leaflet-top.leaflet-right {
    .leaflet-control-locate {
        border-radius: 50%;
        a {
            border-radius: 50%;
            background-color: $color_pink;
            width: 26px;
            height: 26px;
            position: relative;
            span {
                //color: $color_pink;
                &.crosshairs {                    
                    content: "";
                    position: absolute;
                    background-image: url(../svgs/location.svg);
                    width: 18px;
                    height: 18px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);                    
                }
            }
        }
    }
}

.div-ejecutado {
    //background-color: rgba(@color_red, 0.60);
    display: flex;
    align-items: center;
    justify-content: center;
	div {
		display: flex;
		align-items: center;
		justify-content: center;
		&.kine {
			transform: rotate(0deg);
			background-image: url(../svgs/marker-macho.svg);
            background-repeat: no-repeat;
			position: relative;
			animation: kine 1s infinite alternate ease-in-out;
			z-index: 2;
			span {
                position: absolute;
                left: 50%;
                top: 50%;
				transform: translate(-50%, -58%);
                z-index: 344;
			}
			
		}
	}
}

.div-icon-cluster div {
	//border-radius: 100%;
	width: 100px;
	height: 100px;
}
.leaflet-marker-icon.marker-cluster {
    z-index: 999 !important;
}

.div-icon-cluster {
    position: absolute;
    border-radius: 100%;
    text-align: center;
    color: $color_black;
    font-weight: bold;
    font-size: 14px;
    //text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    width: 80px !important;
    height: 80px !important;
    line-height: 80px !important;
    vertical-align: middle;
}
.div-icon-marker-core { /*Central Anillo*/
    /*background: #54c8ff;*/
    border-radius: 100%;
    //line-height: 20px;
    vertical-align: middle;
    font-family: 'Segoe UI', Helvetica, sans-serif;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 21px;
    /*border: 1px solid #ccc;*/
    .thumbnail {
        font-size: 14px;
        img {
            width: 55px;
            height: 55px;
            object-fit: cover;
            background-repeat: no-repeat;
            background-position: 50%;
            border-radius: 50%;
            background-size: 100% auto;
        }
    }
}
.div-icon-marker { /*Ultimo Anillo*/
    position: absolute;
    border-radius: 100%;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    //border: 1px solid #898989;
    padding:4px;
    &:after {
        border: 15px solid;
        //border-color: $color_pink transparent transparent; /*rgba(255, 255, 255,.7) transparent transparent;*/
        content: "";
        left: 50%;
        margin-left: -15px;
        position: absolute;
        bottom: -26px;
    }
    &.Hembra {
        background-color: $color_pink;
        &:after {
            content: "";
            border-color: $color_pink transparent transparent;
        }
    }
    &.Macho {
        background-color: $color_cyan;
        &:after {
            content: "";
            border-color: $color_cyan transparent transparent;
        }
    }
}

.div-icon-marker:hover .tooltip-app {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}
.div-icon-marker.hover .tooltip-app {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}
.div-icon-marker i.icon {
    margin: 0px !important;
}
/*PULSO*/
.pulsex {
    background: rgba(36,43,49,0.3);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: rotateX(55deg);
    z-index: -2;
}
    .pulsex:after {
        content: "";
        border-radius: 50%;
        height: 40px;
        width: 40px;
        position: absolute;
        margin: -13px 0 0 -13px;
        animation: pulsate 1s ease-out;
        animation-iteration-count: infinite;
        opacity: 0;
        box-shadow: 0 0 1px 2px rgba(36,43,49,0.5);
        animation-delay: 1.1s;
    }
@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

.tooltip-app {
    text-align: center;
    background: rgba(255, 255, 255, 1);
    bottom: 100%;
    color: #626262;
    font-size: 11px;
    display: block;
    /* left: 75px; */
    margin-bottom: 0px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    /* width: 100%; */
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
    transform: translate(-50%,0) !important;
    text-shadow: none;
    box-shadow: 0 1px 2px rgba(0,0,0,.1);
    white-space: nowrap;
    width: 200px;
    /*margin-left:10px;*/
    margin-left:27px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    &:before {
        content: " ";
        bottom: -10px;
        height: 0;
        left: 50%;
        position: absolute;
        width: 50px;
        height: 10px;
        background-color: white;
        margin-bottom: 10px;
        margin-left: -25px;
        z-index: 2;
    }
    /* CSS Triangles - see Trevor's post */
    &:after {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid rgba(255, 255, 255, 1);
        bottom: -10px;
        content: " ";
        height: 0;
        left: calc(50% - 10px);
        position: absolute;
        filter: drop-shadow(0 0px 5px rgba(0,0,0,0.7));
    }

    p:nth-child(1) {
        line-height: 17px;
        font-size: 12px;
        font-weight: bold;
    }

    .tooltip-content {
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        border-bottom-left-radius: 8px;
        padding-bottom: 14px;
        &.Hembra {
            h3 {
                background-color: $color_pink;
                color: $color_white;
            }
        }
        &.Macho {
            h3 {
                background-color: $color_cyan;
                color: $color_white;
            }
        }
    }
    img {
        &.img-perfil {
            width: 100%;
            //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            height: 160px;
            object-fit: cover;
            display: block;
        }
        &.img-country {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 2px);
            outline: 1px solid #ffffff;
            z-index: 999;
            width: 35px;
            height: auto;
            overflow: hidden;
            object-fit: cover;
        }
        
    }
    h2 {
        font-size: 16px;
        //padding: 16px 0 7px 0;
        font-weight: bold;
    }
    h3 {
        font-size: 20px;
        border-bottom: 1px dashed $color_cyan;
        font-weight: bold;
        text-transform: uppercase;
        padding-bottom: 3px;
        &:after {
            display: none;
        }
    }
    p {
        margin-bottom: 3px;
        padding: 0;
        white-space: nowrap;
        white-space: normal;
        font-size: 12px;
        line-height: normal;
        i {
            color: $color_cyan;
        }
    }
    .content-mascota-popup {
        position: relative;
        background-color: $color_black;        
        .img-country {
            width: 35px;
            border: 1px solid $color_pink;
        }
        .whatsapp {
            width: 30px;
            height: 30px;
            background: url('/svg/whatsapp.svg') no-repeat;
            position: absolute;
            bottom: 5px;
            left: 5px;
        }
    }
    // .estado {
    //     position: absolute;
    //     text-align: center;
    //     transform: rotate(45deg);
    //     right: -35px;
    //     text-align: center;
    //     background-color: $color_gray;
    //     color: $color_white;
    //     top: 14px;
    //     width: 126px;
    //     z-index: 1;
    //     box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    //     line-height: 30px;
    // }
    
    .view-more {
        margin-top: 10px;
        i {
            color: $color_gray
        }
        
    }
    &.Macho {
        border-left: solid 8px $color_cyan;
    }
    &.Hembra {
        border-left: solid 8px $color_gray !important;
        .estado {
            background: $color_gray !important;
        }
        h3 {
            border-bottom: 1px dashed $color_gray !important;
        }
        h4 {
            color: $color_gray !important;
        }
        p {
            i {
                color: $color_gray !important;
            }
        }
        .view-more {
            a {
                color: $color_gray !important;
                &:hover {
                    background-color: $color_gray !important;
                }
            }
        }
    }
}

 